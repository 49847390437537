import { CustomerAPI } from "@api-clients/customer";
import {
  IBaseSubscription,
  IExtra,
  IFreshMealPlan,
  ILitterPlan,
  IMySubscriptions,
  TBoxNumber,
} from "libs/api/customer/src/lib/types/subscriptions";
import dayjs from "dayjs";

import {
  CustomerStatus,
  ExtraSubscriptionStatus,
  FreshSubscriptionStatus,
  TPlanGift,
} from "./types";
import {
  LITTER_PLAN_GIFT_BY_BOX_NUMBER,
  MEAL_PLAN_GIFT_BY_BOX_NUMBER,
} from "./constants";

export class CustomerState {

  /**
   * A class to determine any customer's status.
   * Status being a culmination of their litter/nibbles/fresh subscription (or lack thereof).
   * e.g. Active fresh, never had scoop (PreInactive), and Inactive nibbles.
   */
  constructor(private readonly customerClient: CustomerAPI) { }

  public async getCustomerStatus(): Promise<CustomerStatus> {
    try {
      const data = await this.customerClient.getAllSubscriptions();
      return CustomerState.determineCustomerStatus(data);
    } catch (error) {
      console.error(error);
    }
    return {
      freshStatus: FreshSubscriptionStatus.UNKNOWN,
      nibblesStatus: ExtraSubscriptionStatus.UNKNOWN,
      scoopStatus: ExtraSubscriptionStatus.UNKNOWN,
    };
  }

  public async getBoxNumbersPerCat(): Promise<Map<string, TBoxNumber>> {
    try {
      const data = await this.customerClient.getAllSubscriptions();
      return CustomerState.determineBoxNumbers(data);
    } catch (error) {
      console.error(error);
    }
    return new Map<string, TBoxNumber>();
  }

  public static determineBoxNumbers(subscriptions: IMySubscriptions): Map<string, TBoxNumber> {
    const boxNumbers = new Map<string, TBoxNumber>();
    subscriptions.freshMealPlans.forEach((freshPlan) => {
      const { boxNumber } = freshPlan;
      if (boxNumber && boxNumber !== "Trial") {
        boxNumbers.set(freshPlan.catId, boxNumber);
      } else {
        boxNumbers.set(freshPlan.catId, boxNumber);
      }
    });
    return boxNumbers;
  }

  public static getTime(date?: string | null) {
    // .valueOf() get the Unix timestamp in milliseconds - .unix() floors to the nearest second
    return date ? dayjs(date).valueOf() : 0;
  }

  public static getCustomerNextDelivery(
    subscriptions: IMySubscriptions,
  ): IFreshMealPlan | ILitterPlan | undefined {
    const nextDelivery = [ ...subscriptions.freshMealPlans, ...subscriptions.litterPlans ].filter((plan) => (
      dayjs(plan.inProgressDeliveryDate).isValid()
      || dayjs(plan.nextDeliveryDate).isValid()))
      .sort((planA, planB) => (
        CustomerState.getTime(planA.inProgressDeliveryDate ?? planA.nextDeliveryDate) -
        CustomerState.getTime(planB.inProgressDeliveryDate ?? planB.nextDeliveryDate)
      ))?.[0];
    return nextDelivery || undefined;
  }

  public static getCustomerNextDeliveryDate(
    subscriptions: IMySubscriptions,
  ): string {
    const nextDelivery = CustomerState.getCustomerNextDelivery(subscriptions);
    const nextDeliveryDate = nextDelivery?.inProgressDeliveryDate ?? nextDelivery?.nextDeliveryDate;
    return nextDelivery
      ? dayjs(nextDeliveryDate).format("ddd MMM D YYYY")
      : "No upcoming deliveries";
  }

  public static getNextDeliveryGift(subscriptions: IMySubscriptions): TPlanGift | undefined {
    const nextDelivery = CustomerState.getCustomerNextDelivery(subscriptions);
    const boxNumber = nextDelivery?.boxNumber;
    const parentProductTitle = nextDelivery?.parentProductTitle;
    if (!boxNumber || !parentProductTitle) return;
    let gift: TPlanGift | undefined;

    if (parentProductTitle === "KATKIN_FRESH_MEAL_PLAN") {
      gift = MEAL_PLAN_GIFT_BY_BOX_NUMBER[boxNumber];
    } else if (parentProductTitle === "ADD_ON_PLAN") {
      gift = LITTER_PLAN_GIFT_BY_BOX_NUMBER[boxNumber];
    }
    return gift;
  }

  public static determineCustomerStatus(
    customerSubscriptions: IMySubscriptions,
  ): CustomerStatus {
    const freshStatus = CustomerState.determineFreshStatus(
      customerSubscriptions.freshMealPlans,
    );
    const nibblesStatus = CustomerState.determineNibblesStatus(
      customerSubscriptions.freshMealPlans.flatMap((fp) => fp.extras),
    );
    const scoopStatus = CustomerState.determineScoopStatus(
      customerSubscriptions.litterPlans,
    );
    return {
      freshStatus,
      nibblesStatus,
      scoopStatus,
    };
  }

  public static determineNibblesStatus(
    extras: IExtra[],
  ): ExtraSubscriptionStatus {
    const filteredExtras = extras.filter((ex) => ex.quantity > 0 && ex.productTitle !== "SPRINKLES");
    return filteredExtras.length
      ? ExtraSubscriptionStatus.Active
      : ExtraSubscriptionStatus.Inactive;
  }

  public static determineFreshStatus(
    freshSubscriptions: IFreshMealPlan[],
  ): FreshSubscriptionStatus {
    if (!freshSubscriptions.length) {
      return FreshSubscriptionStatus.Never;
    }
    if (
      freshSubscriptions.filter((fs) => !!fs.editDeliveryDateUntil).length === 0
    ) {
      if (!freshSubscriptions.some((fs) => !!fs.lastChargeDate)) {
        return FreshSubscriptionStatus.InactiveAwaitingTrial;
      }
      return FreshSubscriptionStatus.Inactive;
    }
    if (!freshSubscriptions.some((fs) => !!fs.lastChargeDate)) {
      return FreshSubscriptionStatus.ActiveAwaitingTrial;
    }
    return FreshSubscriptionStatus.Active;
  }

  public static determineScoopStatus(
    scoopSubscriptions: IBaseSubscription[],
  ): ExtraSubscriptionStatus {
    if (!scoopSubscriptions.length) {
      return ExtraSubscriptionStatus.Never;
    }
    return scoopSubscriptions.some((es) => !!es.editDeliveryDateUntil)
      ? ExtraSubscriptionStatus.Active
      : ExtraSubscriptionStatus.Inactive;
  }

}
