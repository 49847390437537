"use client";

import { FC, useEffect, useState } from "react";
import Balancer from "react-wrap-balancer";

import Text from "apps/website/components/base/Text/Text";
import Button from "apps/website/components/base/Button/Button";
import Spacer from "apps/website/components/layout/Spacer/Spacer";
import { Theme, themeRootClassMap } from "apps/website/maps/Theme.map";
import {
  legacySizeCollectionMap,
} from "apps/website/components/base/Text/Text.map";
import Image, { IImageProps } from "apps/website/components/base/Image/Image";
import useFocusTrap from "apps/website/hooks/useFocusTrap";
import useModal from "apps/website/hooks/useModal";
import Container from "apps/website/components/layout/Container/Container";
import { DisplayState } from "@/constants/state";
import Column from "apps/website/components/layout/Column/Column";
import Icon from "apps/website/components/base/Icon/Icon";
import Tag from "apps/website/components/base/Tag/Tag";

import Modal from "../Modal";
import IconTextList from "../../IconTextList/IconTextList";
import {
  IIconTextItem,
} from "../../IconTextList/IconTextListItem/IconTextListItem";
import CondensedReviewList, {
  ICondensedReviewListProps,
} from "../../CondensedReviewList/CondensedReviewList";
import { AlertPrompt } from "../../AlertPrompt/AlertPrompt";

export type NibblesPurchaseState = "purchased" | "processing" | "failure" | "none";

export interface ICrossSellModalProps {
  id: string;
  title: string;
  subtitle: string;
  image: IImageProps;
  addToOrderButtonText: string;
  onConfirm(): void ;
  onClose(): void;
  isOpen: boolean;
  theme?: Theme;
  modalTitleId: string;
  modalDescriptionId: string;
  footerText: string;
  items: IIconTextItem[];
  review: ICondensedReviewListProps;
  offerTitle: string;
  offerBody: string;
  result?: NibblesPurchaseState;
  buttonProcessingText?: string;
  successText?: string;
  successImage?: IImageProps;
  cardTag?: string;
}

const CrossSellModal: FC<ICrossSellModalProps> = ({
  id,
  title,
  subtitle,
  addToOrderButtonText,
  onConfirm,
  onClose,
  isOpen,
  theme = "light-grey",
  image,
  footerText,
  items,
  review,
  offerTitle,
  offerBody,
  result,
  buttonProcessingText = "Adding...",
  successText = "Added to your ongoing deliveries.",
  successImage,
  cardTag,
}) => {
  const { modalId, modalTitleId, modalDescriptionId, modalButtonId, modalIsOpen, setModalIsOpen } = useModal(isOpen);
  const { initFocusTrap, destroyFocusTrap } = useFocusTrap();
  const [ buttonState, setButtonState ] = useState(DisplayState.READY);

  useEffect(() => {
    if (result === "purchased") {
      setButtonState(DisplayState.COMPLETE);
    } else if (result === "processing") {
      setButtonState(DisplayState.PROCESSING);
    } else if (result === "failure") {
      setButtonState(DisplayState.ERROR);
    }
  }, [ result ]);

  useEffect(() => {
    if (modalIsOpen) {
      initFocusTrap(modalId);
    } else {
      destroyFocusTrap(modalButtonId);
      if (onClose) {
        onClose();
      }
    }
  }, [ destroyFocusTrap, initFocusTrap, modalButtonId, modalId, modalIsOpen, onClose ]);

  return (
    <Modal
      id={id}
      component={CrossSellModal.name}
      isOpen={isOpen}
      onClose={() => { setModalIsOpen(false); }}
      modalTitleId={modalTitleId}
      modalDescriptionId={modalDescriptionId}
      hideCloseButton={false}
      size="sm"
      theme={theme}
    >
      <Container
        className="flex flex-col py-5 px-2 items-center justify-center align-middle"
      >
        { result !== "purchased" ? (
          <><Text
            data-title
            size={legacySizeCollectionMap.titleMd}
            display="subtitle"
            align="center"
          >
            { title }
          </Text><Text
            data-body
            align="center"
            size={legacySizeCollectionMap.titleSm}
            display="subtitle"
          >
            <Balancer>
              { subtitle }
            </Balancer>
          </Text><div className="w-9/12 relative z-20">
            <Image
              image={image} alt="Cross sell product image"></Image>
            { cardTag && (
              <Tag theme="pink" className="absolute top-2 left-2 z-40" whitespace="preWrap">{ cardTag }</Tag>
            ) }
          </div>
          <IconTextList items={items} gap="none" columns="[4,4]" listType="ul" iconWidth={50} />
          <div className="md:my-4">
            <CondensedReviewList {...review} /></div><Spacer size="lg" ></Spacer>
          <Container className="md:w-1/2 px-1 items-center flex justify-center">
            <Button
              onClick={onConfirm}
              size="default"
              state={buttonState}
            >
              { buttonState === DisplayState.PROCESSING ? buttonProcessingText : buttonState === DisplayState.ERROR ? "Something went wrong" : addToOrderButtonText }
            </Button>
          </Container><Spacer size="lg" /><div className="md:w-1/2">
            <AlertPrompt style={"discount"} title={offerTitle} textSize="sm" size="sm">
              <Text align="center" display="default" size="2xs">{ offerBody }</Text>
            </AlertPrompt>
          </div><Spacer size="lg" /><Text
            display="default"
            size="xs"
            align="center"
          >
            { footerText }
          </Text><Spacer size="xl" /></>
        ) :
          (
            <Column className="min-h-96 items-center justify-center text-center px-4">
              { successImage && (
                <div className="w-1/2">
                  <Image
                    image={successImage} alt="Cross sell product image"></Image>
                </div>
              ) }
              <div className={`px-2 lg:px-4 h-11 flex justify-center items-center ${themeRootClassMap.green}`} data-theme="green" data-testid="alwaysInBox">
                <Icon icon="tickHeavy" size="small" />
                <Text tag="span" display="title">
                  In your box
                </Text>
              </div>
              <Spacer size="lg" />
              <Text align={"center"} size={"lg"} display="subtitle">
                { successText }
              </Text>
              <Spacer size="lg" />
              <Spacer size="lg" />
            </Column>
          ) }
      </Container>
    </Modal>
  );
};

export default CrossSellModal;
