export enum ExtraSubscriptionStatus {
  Active,
  Inactive,
  Never,
  UNKNOWN,
}

export enum FreshSubscriptionStatus {
  ActiveAwaitingTrial,
  Active,
  InactiveAwaitingTrial,
  Inactive,
  Never,
  UNKNOWN,
}

export interface CustomerStatus {
  scoopStatus: ExtraSubscriptionStatus;
  nibblesStatus: ExtraSubscriptionStatus;
  freshStatus: FreshSubscriptionStatus;
}

// TODO: Handled here or just on UI?

export enum UpsellBehaviour {
  Scoop,
  Nibbles,
  ReferAFriend,
  ReactivateFresh,
  ReactivateScoop,
}

export enum CTA {
  ManageFreshPlan,
  ReactivateFresh,
  ManageScoopPlan,
}

export enum StatsBehaviour {
  Global,
  Personal,
}

export type TPlanGift = "TOTE_BAG";

export type TBoxGift = {
  Trial?: TPlanGift;
  [key: number]: TPlanGift
};
